import * as styles from "./Footer.module.css";
import IconImage from "../images/icon.svg";

import { Link } from "gatsby";
import * as React from "react";

const Footer = () => (
	<footer className={styles.footer}>
		<Link to="/">
			<img
				className={styles.footer__icon}
				src={IconImage}
				alt="FaintLines icon"
			/>
		</Link>
		<ul className={styles.footer__links}>
			<li>
				<Link to="/privacy">{"Privacy"}</Link>
			</li>
			<li>
				<Link to="/termsofuse">{"Terms of Use"}</Link>
			</li>
			{/* <li> */}
			{/* 	<a href="/careers">{"Careers"}</a> */}
			{/* </li> */}
		</ul>
		<div className={styles.footer__info}>
			<p>{"FaintLines, Inc."}</p>
			<p className={styles.footer__info__copyright}>
				{"Ⓒ All Rights Reserved"}
			</p>
		</div>
	</footer>
);

export default Footer;
