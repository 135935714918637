import "./Layout.css";
import Footer from "../components/Footer";
import Menu from "../components/Menu";
import LogoImage from "../images/logo.svg";

import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import * as React from "react";
import classnames from "classnames";

export default function Layout({ title, className, children }) {
    return (
        <div className={classnames("layout", className)}>
            <Helmet
                title={title}
                defer={false}
                htmlAttributes={{
                    lang: "en",
                }}
            >
                <meta
                    name="description"
                    content="When the power of AI meets the creativity of great storytellers, we have a whole new way to experience stories. They become adventures."
                />
            </Helmet>
            <Menu />
            <div className="logoWrapper">
                <Link to="/">
                    <img
                        className="logo"
                        alt="FaintLines Logo"
                        src={LogoImage}
                    />
                </Link>
            </div>
            {children}
            <Footer />
        </div>
    );
}
