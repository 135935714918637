import * as styles from "./Menu.module.css";

import React, { useState } from "react";
import classnames from "classnames";
import { Link } from "gatsby";

export default function Menu() {
	const [open, toggle] = useState(false);

	const close = () => toggle(false);

	return (
		<div className={classnames(styles.wrapper, open ? styles.open : null)}>
			<div className={styles.button} onClick={() => toggle((x) => !x)}>
				<div className={styles.button__line} />
				<div className={styles.button__line} />
				<div className={styles.button__line} />
			</div>
			<div className={styles.menu}>
				<ul className={styles.menu__list}>
					<MenuItem linkTo="/" onClick={close}>
						{"Home"}
					</MenuItem>
					<MenuItem linkTo="/#technology" onClick={close}>
						{"Technology & Platform"}
					</MenuItem>
					<MenuItem linkTo="/#products" onClick={close}>
						{"Built on Our Platform"}
					</MenuItem>
					{/* <MenuItem linkTo="/#team" onClick={close}> */}
					{/* 	{"Team"} */}
					{/* </MenuItem> */}
				</ul>
			</div>
		</div>
	);
}

function MenuItem({ children, linkTo, onClick }) {
	return (
		<li className={classnames(styles.menu__item, "h2")}>
			<Link
				className={styles.menu__item__link}
				to={linkTo}
				onClick={onClick}
			>
				{children}
			</Link>
		</li>
	);
}
