import * as styles from "./Document.module.css";
import Layout from "./Layout";

import * as React from "react";

export default function DocumentLayout({ title, children }) {
    return (
        <Layout title={title} className={styles.document}>
            <div className={styles.container}>{children}</div>
        </Layout>
    );
}
