import DocumentLayout from "../../../../src/layouts/Document";
import { Indent } from "../../../../src/components/DocumentParts";
import imagePN from "../../../../src/images/pn.png";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  DocumentLayout,
  Indent,
  imagePN,
  Link,
  React
};